import * as React from 'react'
import './page-template.scss'
import Layout from '../../layouts'

const PageTemplate = (props: any) => {
  const pageDetails = props.pathContext.page
  const html = pageDetails.body.childMarkdownRemark.html

  return (
    <Layout>
      <div className="page">
        <h1>{pageDetails.title}</h1>
        <div
          className="page-body"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </div>
    </Layout>
  )
}

export default PageTemplate
